<template>
  <v-card class="pa-0 mt-2" rounded="md" flat>
    <v-card-title v-if="account.logo">
      <v-row class="px-3 pt-5" justify="center">
        <v-sheet class="overflow-hidden" width="90" outlined>
          <v-responsive class="align-center " :aspect-ratio="1/1">
            <v-sheet class="px-1" color="white" width="90">
              <v-img :src="account.logo.split(/[?#]/)[0]" width="100%" />
            </v-sheet>
          </v-responsive>
        </v-sheet>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="white-0">
      <v-row :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`">
        <v-col>
          <span class="d-block text-h4 grey-700--text font-weight-bold mt-1">{{activeDocument.name}} Nº {{instance.number}} </span>
          <v-col class="text-left mt-2">
            <span class="body-1 grey-700--text font-weight-bold">
              <span class="font-weight-bold body-1 grey-700--text">De:</span> {{account.name}}</span>
            <span class="d-block body-1 grey-700--text font-weight-regular pt-1">Para: {{((instance || {}).taxpayer || {}).name}}</span>
            <!-- <span class="d-block body-1 body-1 font-weight-medium mt-3">{{instance.total | currency(instance.currency)}} {{instance.currency}} del {{instance.date | date}}</span> -->
          </v-col>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-text :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`">
      <v-row>
        <v-col class="px-0 pb-1" cols="12">
          <v-textarea class="elevation-0" v-model="messageBody" @input="updateMessage($event)" :placeholder="placeholder" auto-grow solo flat hide-details />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
  </v-card>
</template>
<script>
import ReminderViewMixin from '@/mixins/ReminderViewMixin'
export default {
  mixins: [
    ReminderViewMixin
  ]
}
</script>