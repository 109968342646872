<template>
  <v-sheet class="pb-4 px-5 text-center transparent" max-width="600">
    <img class="mt-6 mb-3" v-if="env === 'fc'" :src="require(`@/assets/appAsset-brand--fc.svg`)" height="24" />
    <img v-else class="mt-6 mb-3" :src="require('@/assets/appLogo-color--Onlight.svg')" width="24"/>
    <span class="d-block body-2 grey-700--text">Estás recibiendo este email porque eres cliente de {{account.name}}, quien utiliza Axteroid para sus procesos de emisión.</span>
  </v-sheet>
</template>
<script>
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      env: process.env?.VUE_APP_MODE ?? 'ax'
    }
  }
}
</script>